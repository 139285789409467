<template>
  <div class="settings-avatar">
    <portal to="topbar-left">
      <router-link
        :to="{ name: 'profile' }"
        class="flex flex-row items-center w-auto h-10 ml-10 space-x-3 font-bold"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 12 22"
          class="w-6 h-6"
        >
          <g
            fill="currentColor"
            fill-rule="evenodd"
            transform="translate(-4 -1)"
          >
            <path
              d="M7.621 12l7.94-7.94a1.5 1.5 0 00-2.122-2.12l-9 9a1.5 1.5 0 000 2.12l9 9a1.5 1.5 0 002.122-2.12L7.62 12z"
            />
          </g>
        </svg>
        <span class="text-xs">{{ $t("action.cancel") }}</span>
      </router-link>
    </portal>

    <form
      @submit.prevent="submitForm"
      @keydown="form.onKeydown($event)"
      name="login"
      class="flex flex-col max-w-xl p-4 mx-auto space-y-4"
      aria-autocomplete="off"
      autocomplete="off"
    >
      <avatar-selector
        :avatar="form.avatar"
        @avatar-changed="handleChangedAvatar"
      />
      <t-button type="submit" :disabled="form.busy">{{
        form.busy ? $t("action.saving") : $t("profile.btn--update-avatar")
      }}</t-button>
    </form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { USER_REQUEST } from "actions/user";
import Repository from "repositories/RepositoryFactory";

import { Form } from "vform";
import AvatarSelector from "components/AvatarSelector";
const UserRepository = Repository.get("user");

export default {
  name: "SettingsAvatar",
  components: {
    AvatarSelector
  },
  data: () => ({
    form: new Form({
      avatar: null
    }),
    form_loading: false,
    form_error: "",
    hasChanged: false
  }),
  computed: {
    ...mapGetters(["getAvatar"])
  },
  methods: {
    handleChangedAvatar(value) {
      this.form.avatar = value;
    },
    submitForm() {
      this.form_loading = true;
      const { avatar } = this.form;
      UserRepository.updateAvatar(avatar)
        .then(() => {
          this.$router.push({ name: "profile" });
        })
        .catch(err => {
          console.log("catched uncatched error", err.response);
          if (err.response && err.response.status) {
            switch (err.response.status) {
              case 422:
                this.form.errors.set(err.response.data.errors);
                this.form_error = err.response.data.message;
                return;
            }
          }
          this.formErrors = ["Failed to update avatar."];
        })
        .then(() => {
          this.form_loading = false;
          this.$store.dispatch(USER_REQUEST);
        });
    }
  },
  created() {
    this.$store.dispatch(USER_REQUEST);
    this.form.avatar = this.getAvatar;
  }
};
</script>
